import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import Icon from 'components/icons/icon'

import theme, {bp, color} from 'settings/theme'

type Props = {
  menuOpen: string,
  toggleMenu: (menuName: string) => void
}

// Component
const Component: React.FC<Props> = (props) => {

  const handleChatClick = (e) => {
    (window as any).drift?.api.toggleChat();
  };

  return (
    <StyledComponent className="footerMain" >

      <ul className="footerMain-menu">

        <li className="footerMain-menu-products">
          <button onClick={() => props.toggleMenu('products')}>
            <div className="footerMain-menu-icon">
              <Icon iconName="wheelchair" />
            </div>
            <div className="footerMain-menu-text">
              PRODUCTS
            </div>
          </button>
        </li>

        <li className="footerMain-menu-showroom">
          <Link href="/showroom">
            <div className="footerMain-menu-icon">
              <Icon iconName="showroom" />
            </div>
            <div className="footerMain-menu-text">
              SHOWROOM
            </div>
          </Link>
        </li>

        <li className="footerMain-menu-search">
          <button onClick={() => props.toggleMenu('search')}>
            <div className="footerMain-menu-icon">
              <Icon iconName="magnifying-glass" />
            </div>
            <div className="footerMain-menu-text">
              SEARCH
            </div>
          </button>
        </li>

        <li className="footerMain-menu-info">
          <button onClick={() => props.toggleMenu('info')}>
            <div className="footerMain-menu-icon">
              <Icon iconName="info" />
            </div>
            <div className="footerMain-menu-text">
              INFO
            </div>
          </button>
        </li>

        <li className="footerMain-menu-chat">
          <button onClick={handleChatClick}>
            <div className="footerMain-menu-icon">
              <Icon iconName="chat" />
            </div>
            <div className="footerMain-menu-text">
              CHAT
            </div>
          </button>
        </li>

      </ul>

    </StyledComponent>   
  );

}

export default Component


const StyledComponent = styled.nav`

    z-index:3;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: ${color.white};

    .footerMain-menu{

      display: flex;
      width: 100%;

      li{
        flex: 1 1 auto;
        display: grid;
        align-items: stretch;
        justify-items: stretch;
        min-width: 40px;
      }

      a, button{

        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${color.grey(40)};
        font-family: ${theme.typography.fontFamilySecondary};
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
        border-top: solid 1px ${color.lines};
        background-color: ${color.white};
        transition: all 0.15s ease-out;


        .footerMain-menu-icon{

          display: grid;
          align-items: end;
          justify-items: center;
          height: 38px !important;

          svg{
            path{fill: ${color.grey(40)};}
          }

        }

        .footerMain-menu-text{
          height: 22px;
        }

        &:hover{
          border-top-color: ${color.pageBackground};
          background-color: ${color.pageBackground};
        }

      }

      .footerMain-menu-products{

        .footerMain-menu-icon{
          padding-bottom: 5px;
        }

        .icon{
          width: 20px;
          height: 20px;
        }

      }

      .footerMain-menu-showroom{

        .footerMain-menu-icon{
          padding-bottom: 1px;
        }

        .icon{
          width: 28px;
          height: 28px;
        }

      }

      .footerMain-menu-info{

        .footerMain-menu-icon{
          padding-bottom: 5px;
        }

        .icon{
          width: 20px;
          height: 20px;
        }

      }

      .footerMain-menu-search{

        .footerMain-menu-icon{
          padding-bottom: 6px;
        }

        .icon{
          width: 18px;
          height: 18px;
        }

      }

      .footerMain-menu-chat{

        a, button{
          color: ${color.primary};
        }

        .footerMain-menu-icon{
          padding-bottom: 5px;
        }

        .icon{

          width: 20px;
          height: 20px;

          svg {
            path{fill: ${color.primary};}
          }
            
        }

      }

    }


  @media (min-width: ${bp(3)}) {

    .footerMain-menu{

      a, button{

        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 13px;

        .footerMain-menu-icon{

          padding-bottom: 0 !important;
          height: auto !important;
          padding-right: 12px;

          .icon{
            transform: scale(1.2);
          }

        }

        .footerMain-menu-text{
          height: auto !important;
        }

      }

    }

  }

`