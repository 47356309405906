import React, {useMemo} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';

import Button from 'components/button';

import HeaderCloseBar from 'components/layout/header/headerCloseBar';

import theme, {bp, color} from 'settings/theme';


// Component
const Component = (props) => {

  const content = useMemo(() => props.masterTemplateData?.content ?? {}, [props.masterTemplateData?.content])

  return (

    <StyledComponent {...props} className="contactMenuLarge">
      <div className="contactMenuLarge-inner">

        {/* Map */}
        <div className="contactMenuLarge-map">
          <iframe
            frameBorder="0"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCRxBe-EOTm3aUzYPEIHOWt-v2TTUoPBCU&q=${encodeURI(content.map_location)}&zoom=10`} 
            allowFullScreen
            className="contactMenuLarge-map-gmap"
          >
          </iframe>
        </div>

        {/* Details */}
        <div className="contactMenuLarge-details">
            
          {/* Intro */}
          <div className="contactMenuLarge-details-block">
            <div className="contactMenuLarge-intro content" style={{whiteSpace: 'pre-line'}}>
              {content.contact_intro}
            </div>
          </div>

          <div className="contactMenuLarge-details-columns">
            <div className="contactMenuLarge-details-columns-left">
              <div className="contactMenuLarge-details-block">
                <div className="contactMenuLarge-details-title">
                  Phone
                </div>
                <div>{content.phone_number}</div>
              </div>
              <div className="contactMenuLarge-details-block">
                <div className="contactMenuLarge-details-title">
                  Opening Times
                </div>
                <div>{content.opening_times}</div>
                <div className="contactMenuLarge-details-book">
                  <Button
                    as="a"
                    href="https://calendly.com/wheelfreedom-showroom?utm_source=contactusdropdown"
                    height="40px"
                    corners="4px"
                    color="orange"
                    horizontalPadding="16px"
                  >
                    BOOK AN APPOINTMENT
                  </Button>
                </div>
              </div>
            </div>
            <div className="contactMenuLarge-details-columns-right">
              <div className="contactMenuLarge-details-block">
                <div className="contactMenuLarge-details-title">
                  Address
                </div>
                <div>{content.address}</div>
                <div className="textSize80" style={{marginTop:'4px'}}>{content.address_note}</div>
              </div>
              <div className="contactMenuLarge-details-block">
                <Link href="/contact" passHref>
                  <Button
                    corners="4px"
                  >
                    {content.contact_message_button_text}
                  </Button>
                </Link>
              </div>
              <div className="contactMenuLarge-details-socials">
                <a href="https://x.com/wheelfreedom" target="_blank">
                  <Image src="/images/social/x.svg" alt="X" width="32" height="32" />
                </a>
                <a href="https://www.instagram.com/wheelfreedom" target="_blank">
                  <Image src="/images/social/instagram.svg" alt="Instagram" width="32" height="32" />
                </a>
                <a href="https://www.facebook.com/wheelfreedom" target="_blank">
                  <Image src="/images/social/facebook.svg" alt="Facebook" width="32" height="32" />
                </a>
                <a href="https://www.youtube.com/channel/UCX5a73DANfiaSa57FJj5mRw" target="_blank">
                  <Image src="/images/social/youtube.svg" alt="Youtube" width="32" height="32" />
                </a>
                <a href="https://www.linkedin.com/company/wheelfreedom" target="_blank">
                  <Image src="/images/social/linkedin.svg" alt="LinkedIn" width="32" height="32" />
                </a>
              </div>
            </div>
          </div>

        </div>

      </div>
      <HeaderCloseBar onClick={props.unsetItemHovered} />
    </StyledComponent>
    
  );

}

export default Component;


const StyledComponent = styled.nav`

  display: block;
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 15px;
  border-bottom: solid 1px ${color.lines};
  box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
  background-color: ${color.pageBackground};

  .contactMenuLarge-inner{
    display: grid;
    grid-template-columns: 40% 60%;
    max-height: calc(100vh - 180px);
  }

  .contactMenuLarge-map{

    height: 100%;
    max-height: calc(100vh - 180px);
    background-color: ${color.grey(90)};

    .contactMenuLarge-map-gmap{
        width: 100%;
        height: 100%;
    }

  }

  .contactMenuLarge-details{
    max-height: calc(100vh - 180px);
    padding: 40px 60px;
    overflow-y: auto
  }

  .contactMenuLarge-intro{}

  .contactMenuLarge-details-columns{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 40px;
  }

  .contactMenuLarge-details-block{
    margin-bottom: 30px;
  }

  .contactMenuLarge-details-title{
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  .contactMenuLarge-details-book{
    margin-top: 16px;
  }

  .contactMenuLarge-details-socials{
    display: flex;
    gap: 8px;
  }


`;