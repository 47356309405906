import React from 'react';
import styled from 'styled-components';

import Icon from 'components/icons/icon';

import theme, {bp, color} from 'settings/theme';


// Component
const Component = (props) => {

  return (

    <StyledComponent {...props} className="headerCloseBar">
      <div className="headerCloseBar-inner">
        <div className="headerCloseBar-text">
          CLOSE
        </div>
        <div className="headerCloseBar-icon">
          <Icon iconName="chevron" iconDirection="up" />
        </div>
      </div>
    </StyledComponent>
      
  );

}

export default Component


const StyledComponent = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: ${color.grey(20)};
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 15px;
  font-weight: bold;
  border-top: solid 1px ${color.lines};
  background-color: ${color.white};
  transition: color 0.15s ease-out;

  cursor: pointer;

  .headerCloseBar-inner{
    display: flex;
  }

  .headerCloseBar-text{
    margin-right: 10px;
  }

  .headerCloseBar-icon .icon{

    width: 14px;

    svg{
      path{fill: ${color.grey(20)};}
    }

  }

  &:hover{

    color: ${color.black};

    .headerCloseBar-icon .icon{

      svg{
        path{fill: ${color.black};}
      }

    }

  }

`