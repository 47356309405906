import React, {useEffect, useReducer} from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import HeaderMain from 'components/layout/header/headerMain';
import ProductsMenuLarge from 'components/layout/header/productsMenuLarge';
import InfoMenuLarge from 'components/layout/header/infoMenuLarge';
import ContactMenuLarge from 'components/layout/header/contactMenuLarge';


import theme, {bp, color} from 'settings/theme';

import useBreakpoints from 'hooks/useBreakpoints';


// Initial State
const initialState = {
    itemHovered: 'products'
}

// Reducer
const reducer = (state, action) => {

    switch (action.type) {
        case 'setHovered':
            return {...state, itemHovered: action.payload};
        default:
            throw new Error();
    }

}

type Props = {
    masterTemplateData: any
}

// Component
const Header: React.FC<Props> = (props) => {

    // console.log("props", props.masterTemplateData.content.top_menu_categories)

    const [state, dispatch] = useReducer(reducer, initialState);

    const currentBreakpoint: any = useBreakpoints()

    const setItemHovered = (itemName) => {
      dispatch({type:'setHovered', payload:itemName})
    }

    const unsetItemHovered = () => {
      dispatch({type:'setHovered', payload:'products'})
    }


    return (
        <>

            <StyledSpacer></StyledSpacer>

            <StyledComponent className="header">
                
                <HeaderMain itemHovered={state.itemHovered} setItemHovered={setItemHovered} unsetItemHovered={unsetItemHovered} />

                {currentBreakpoint.size >= 4 && 
                
                    <div className="header-submenus">

                        <TransitionGroup>

                            <div className="header-submenu header-submenu--products">
                                <ProductsMenuLarge categories={props.masterTemplateData?.content?.top_menu_categories} />
                            </div>

                            {state.itemHovered === 'info' &&
                                <CSSTransition classNames="anim" timeout={{ enter:150, exit: 2 }}>
                                    <div 
                                        onMouseEnter={() => {setItemHovered('info')}}
                                        onMouseLeave={() => {unsetItemHovered()}}
                                        className="header-submenu header-submenu--info"
                                    >
                                        <InfoMenuLarge masterTemplateData={props.masterTemplateData} unsetItemHovered={unsetItemHovered} />
                                    </div>
                                </CSSTransition>
                            }

                            {state.itemHovered === 'contact' &&
                                <CSSTransition classNames="anim" timeout={{ enter:150, exit: 2 }}>
                                    <div 
                                        onMouseEnter={() => {setItemHovered('contact')}}
                                        onMouseLeave={() => {unsetItemHovered()}}
                                        className="header-submenu header-submenu--contact" 
                                    >
                                        <ContactMenuLarge masterTemplateData={props.masterTemplateData} unsetItemHovered={unsetItemHovered} />
                                    </div>
                                </CSSTransition>
                            }

                        </TransitionGroup>

                    </div>
                
                }

            </StyledComponent>   

        </>
    )

}

export default Header


const StyledComponent = styled.header`

    z-index: 1;
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    background-color: ${color.blue(95)};

    .header-submenus{
        position: relative;
    }

    .header-submenu{
        
        position: absolute;
        top: 0;
        width: 100%;

        &:not(.header-submenu--products){
            z-index:1;
        }

    }

    .anim-enter {
        opacity: 0;
        transform: translateY(-20px);
    }

    .anim-enter.anim-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.15s ease-out;
    }
    

`


const StyledSpacer = styled.div`
    
    display: block;
    height: 60px;

    @media (min-width: ${bp(4)}) {

        height: 140px;

    }
    

`