import React, {useState} from 'react'
import { useRouter } from 'next/router'
import styled, {css} from 'styled-components'

import Icon from 'components/icons/icon'

import FooterCloseBar from 'components/layout/footer/footerCloseBar'

import theme, {bp, color} from 'settings/theme'


type Props = {
    isOpen: boolean,
    toggleMenu: (menuName: string) => void
}

type StyledProps = {
    isOpen: boolean
}


// Component
const Component: React.FC<Props> = (props) => {

    const router = useRouter()

    // Keyword Box
    const [keywordsInput, setKeywordsInput] = useState('')

    const handleKeywordsChange = (e) => {
        setKeywordsInput(e.target.value)
    }

    const handleKeywordsSubmit = (e) => {

        e.preventDefault()

        window.scrollTo(0, 0)

        props.toggleMenu('')

        router.push({
            pathname: '/products',
            query: {keywords: keywordsInput}
        })

    }

    return (

      <StyledComponent isOpen={props.isOpen} className="searchMenuSmall">
          
        <FooterCloseBar toggleMenu={props.toggleMenu} />

        <div className="searchMenuSmall-inner">
            
          <h4>Search All Products</h4>

          <form onSubmit={handleKeywordsSubmit} className="searchMenuSmall-form">
            <input name="search" type="text" value={keywordsInput} onChange={handleKeywordsChange} placeholder="e.g. 'Foldalite Pro'" />
            <button type="submit">
              <Icon iconName="magnifying-glass" />
            </button>
          </form>

        </div>

      </StyledComponent> 
        
    )

}

export default Component


const StyledComponent = styled.div<StyledProps>`

    z-index: 2;
    position: fixed;
    bottom: 60px;
    display: block;
    width: 100%;
    background-color: ${color.pageBackground};

    opacity: ${props => props.isOpen ? 1 : 0} ;
    transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(30%)'} ;
    transition: all 0.1s ease-out;

    pointer-events: none;

    ${props => props.isOpen && css`
        pointer-events: all;
    `}

    .searchMenuSmall-inner{
        padding: 30px;
    }

    h4{
        margin-bottom: 10px;
    }


    form.searchMenuSmall-form{

        position: relative;
        height: 100%;

        input{

            width: 100%;
            height: 40px;
            padding-left: 10px;
            color: ${color.grey(30)};
            font-size: 16px;
            border-radius: 4px;
            border: solid 1px ${color.lines};
            background-color: white;
            outline: none;

            &:focus{
                outline: none;
                border: solid 1px rgba(0,157,224,1);
            }

        }

        button{

            position: absolute;
            right: 1px;
            top: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 3px;
            transition: background-color 0.15s ease-out;

            .icon{

                width: 16px;
                height: 16px;

                svg{
                    fill: ${color.grey(30)};
                }

            }

            &:hover, &:focus{
                background-color: ${color.grey(90)};
            }

        }

    }

    



`
