import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import Icon from 'components/icons/icon';

import theme, {bp, color} from 'settings/theme';


// Component
const Component = (props) => {

  return (
    <StyledComponent {...props}>

      {/* Logo */}
      <div className="header-logo">
        <Link href="/">
          <img src="/images/logo.png" alt="WheelFreedom Logo" width="320" height="108" />
        </Link>
      </div>

      {/* Mobile Contact Button */}
      <div className="header-contactSmall">
        <Link href="/contact">
          <span><Icon iconName="contact" /></span>
          <span>CONTACT</span>
        </Link>
      </div>

      {/* Large Menu */}
      <div className="header-menuLarge">
        <nav>
          <button
            onMouseEnter={() => {props.setItemHovered('products')}}
            onMouseLeave={() => {props.unsetItemHovered()}}
            className={props.itemHovered === 'products' ? 'header-menuLarge-item--hovered' : ''}
          >
            <div className="header-menuLarge-inner">
              <div className="header-menuLarge-icon header-menuLarge-icon--products">
                <Icon iconName="wheelchair" />
              </div>
              <div className="header-menuLarge-text">
                PRODUCTS
                <span className="header-menuLarge-chevron">
                  <Icon iconName="chevron" />
                </span>
              </div>
              <div className="header-menuLarge-arrow"></div>
            </div>
          </button>
          <Link href="/[...slug]" as="/showroom" onMouseEnter={() => {props.setItemHovered('showroom')}} onMouseLeave={() => {props.unsetItemHovered()}}>
            <div className="header-menuLarge-inner">
              <div className="header-menuLarge-icon header-menuLarge-icon--showroom">
                <Icon iconName="showroom" />
              </div>
              <div className="header-menuLarge-text">
                <span className="header-menuLarge-hiddenWord">OUR </span>SHOWROOM
              </div>
              <div className="header-menuLarge-arrow"></div>
            </div>
          </Link>
          
          <button 
            onMouseEnter={() => {props.setItemHovered('info')}}
            onMouseLeave={() => {props.unsetItemHovered()}}
            className={props.itemHovered === 'info' ? 'header-menuLarge-item--hovered' : ''}
          >
            <div className="header-menuLarge-inner">
              <div className="header-menuLarge-icon header-menuLarge-icon--info">
                <Icon iconName="info" />
              </div>
              <div className="header-menuLarge-text">
                INFORMATION
                <span className="header-menuLarge-chevron">
                  <Icon iconName="chevron" />
                </span>
              </div>
              <div className="header-menuLarge-arrow"></div>
            </div>
          </button>
          <Link href="/products" onMouseEnter={() => {props.setItemHovered('products')}} onMouseLeave={() => {props.unsetItemHovered()}}>
            <div className="header-menuLarge-inner">
              <div className="header-menuLarge-icon header-menuLarge-icon--search">
                <Icon iconName="magnifying-glass" />
              </div>
              <div className="header-menuLarge-text">
                SEARCH
              </div>
              <div className="header-menuLarge-arrow"></div>
            </div>
          </Link>
          <button
            onClick={() => {props.setItemHovered('contact')}}
            onMouseEnter={() => {props.setItemHovered('contact')}}
            onMouseLeave={() => {props.unsetItemHovered()}}
            className={props.itemHovered === 'contact' ? 'header-menuLarge-item--hovered' : ''}
          >
            <div className="header-menuLarge-inner">
              <div className="header-menuLarge-icon header-menuLarge-icon--contact">
                <Icon iconName="contact" />
              </div>
              <div className="header-menuLarge-text">
                CONTACT<span className="header-menuLarge-hiddenWord"> US</span>
                <span className="header-menuLarge-chevron">
                  <Icon iconName="chevron" />
                </span>
              </div>
              <div className="header-menuLarge-arrow"></div>
            </div>
          </button>
        </nav>
      </div>

    </StyledComponent>   
  );

}

export default Component


const StyledComponent = styled.div`

  position: relative;
  z-index: 1;
  
  display: flex;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 1px ${color.lines};
  background-color: ${color.white};


  .header-logo{

    display: flex;
    flex: 0 0 180px;
    align-items: center;
    transform: translateY(2px);

    img{
      width: 132px;
      height: auto;
    }

  }

  .header-contactSmall{

    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${color.grey(20)};
    font-family: ${theme.typography.fontFamilySecondary};
    font-weight: bold;

    > a{

      display: flex;
      align-items: center;
      

      > :first-child{

        margin-right: 8px;

        svg{
          width: 24px; 
          path{fill: ${color.grey(20)}}
        }

      }

      &:hover{
        opacity: 0.75;
      }

    }

  }

  .header-menuLarge{

    display: none;
    flex: 1 1 auto;
    justify-content: flex-end;
    color: ${color.grey(40)};

    nav{

      display: flex;
      align-items: center;
      justify-content: flex-end;

      a, button{

        display: flex;
        align-items: center;
        height: 91px;
        padding-left: 24px;
        padding-right: 24px;
        color: ${color.grey(40)};
        font-family: ${theme.typography.fontFamilySecondary};
        font-weight: bold;
        border-bottom: solid 1px ${color.lines};
        transition: all 0.15s ease-out;

        .header-menuLarge-inner{
            display: flex;
            align-items: center;
        }

        .header-menuLarge-icon{

          height: 100%;

          .icon{
            width: 24px; 
            transform: translateY(1px);
          }

          svg{
            path{fill: ${color.grey(40)};}
          }

          &.header-menuLarge-icon--showroom{
            .icon{width: 40px; height: 40px;}
          }

          &.header-menuLarge-icon--search{
            .icon{width: 20px; height: 20px;}
          }

        }

        .header-menuLarge-text{

          position: relative;
          height: 100%;
          margin-left: 8px;

          .header-menuLarge-chevron{

            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(14px);
            transition: transform 0.15s ease-out;

            .icon{
              width: 12px;
              opacity: 0;
              transition: all 0.15s ease-out;
            }

            svg{
              path{fill: ${color.grey(20)};}
            }

          }

        }

        &.header-menuLarge-item--hovered, &:hover{

          border-bottom-color:${color.pageBackground};
          background-color: ${color.pageBackground};

          .header-menuLarge-text{

            .header-menuLarge-chevron{

              transform: translateX(-50%) translateY(18px);

              .icon{
                width: 12px;
                opacity: 1;
              }

            }

          }

        }

      }

    }

    .header-menuLarge-hiddenWord{
      display: none;
    }

  }

  @media (min-width: ${bp(4)}) {

    height: 90px;
    padding-left: 40px;
    padding-right: 0;

    .header-logo{
            
      flex: 0 0 180px;

      img{
        width: 180px;
      }

    }

    .header-contactSmall{
      display: none;
    }
    

    .header-menuLarge{
      display: flex;
    }

  }

  @media (min-width: ${bp(5)}) {

    .header-menuLarge{

      .header-menuLarge-hiddenWord{
        display: inline;
      }

    }

  }
    

`