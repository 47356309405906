import React, {useMemo} from 'react';
import Link from 'next/link';
import styled, {css} from 'styled-components';

import Button from 'components/button';

import FooterCloseBar from 'components/layout/footer/footerCloseBar';
import FooterMenuTitle from 'components/layout/footer/footerMenuTitle';
import FooterMenuItem from 'components/layout/footer/footerMenuItem';

import theme, {bp, color} from 'settings/theme';


type Props = {
    isOpen: boolean,
    toggleMenu: (menuName: string) => void,
    masterTemplateData: any
};

type StyledProps = {
    isOpen: boolean
};


// Component
const Component: React.FC<Props> = (props) => {

  const categories = useMemo(() => {

    let cats = [...props.masterTemplateData?.content?.top_menu_categories ?? []];

    if(cats[0]){
      cats.pop();
      return cats;
    }
    else{
      return [];
    }
      
  }, [props.masterTemplateData?.content?.top_menu_categories]);

  return (

    <StyledComponent isOpen={props.isOpen} className="productMenuSmall">
        
      <div className="productMenuSmall-close">
        <FooterCloseBar toggleMenu={props.toggleMenu} />
      </div>
      

      <div className="productMenuSmall-region">

        <div className="productMenuSmall-region-map">
          <img src="/images/footer-products-map-se.png" alt="Map of South East and London" width="40" height="30" />
        </div>
        <div className="productMenuSmall-region-details">
          <h4>London and the South East</h4>
          <div className="productMenuSmall-region-description">
            Our full range of products, available to buy/hire/Motability with fast delivery from our Chessington showroom.
          </div>
        </div>

      </div>

      <div className="productMenuSmall-main">

        <FooterMenuTitle>Our Products</FooterMenuTitle>

        {categories.map(item => (
          <FooterMenuItem href="/category/[category-slug]" as={`/${item.link.cached_url}`} key={item._uid}>
            {item.name}
          </FooterMenuItem>
        ))}

      </div>

      <div className="productMenuSmall-region">

        <div className="productMenuSmall-region-map">
          <img src="/images/footer-products-map-uk.png" alt="Map of UK" width="104" height="115" />
        </div>
        <div className="productMenuSmall-region-details">
          <h4>National Hire</h4>
          <div className="productMenuSmall-region-description">
              Manual wheelchairs, knee scooters and rollators available for hire nationally with next-day delivery
          </div>
          <div className="productMenuSmall-region-button">
            <Link href="/category/[category-slug]" as="/category/national-wheelchair-hire">
              <Button
                height="36px"
                corners="4px"
                fontSize="12px"
                color="green"
              >
                VIEW NATIONAL HIRE PRODUCTS
              </Button>
            </Link>
          </div>
        </div>
          
      </div>

    </StyledComponent> 
    
  )

}

export default Component


const StyledComponent = styled.div<StyledProps>`

  z-index: 2;
  position: fixed;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 120px);
  background-color: ${color.pageBackground};

  opacity: ${props => props.isOpen ? 1 : 0} ;
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(30%)'} ;
  transition: all 0.1s ease-out;

  pointer-events: none;

  ${props => props.isOpen && css`
    pointer-events: all;
  `}

  .productMenuSmall-close{
    flex: 0 0 auto;
  }

  .productMenuSmall-region{

    flex: 0 0 auto;
    display: flex;
    padding: 12px 0;

    .productMenuSmall-region-map{

      flex: 0 0 auto;
      justify-content: center;
      width: 25%;
      padding: 10px;

      img{
        width: 100%;
        max-width: 100px;
        height: auto;
      }

    }

    .productMenuSmall-region-details{

      flex: 1 1 auto;
      padding-right: 12px;
      font-size: 12px;

      h4{
        font-size: 14px;
      }

      .productMenuSmall-region-button{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

    }

  }

  .productMenuSmall-main{

    flex: 1 1 auto;
    padding: 24px 30px;
    border-top: solid 1px ${color.lines};
    border-bottom: solid 1px ${color.lines};
    background-color: white;

    overflow-y: auto;

  }

  @media (min-width: ${bp(3)}) {

    .productMenuSmall-region{

      padding: 20px 0;

      .productMenuSmall-region-details{

        padding-right: 30px;
        font-size: 14px;

        h4{
          font-size: 16px;
        }

      }

    }

    .productMenuSmall-main{
      padding: 40px 50px;
    }

  }

`
