import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

import getTemplateFromUrl from 'utils/getTemplateFromUrl';


// Component
const Component = (props) => {

  const categories = props.categories?.map(item => {

    if(item.link?.linktype === "story"){
      item.href = getTemplateFromUrl(item.link?.cached_url);
    }

    return item;

  });

  return (

    <StyledComponent {...props} className="productsMenuLarge">
      <ul>
        {categories?.map((category, index) => (
          <li key={index}>
            {category.link?.linktype === "story" &&
              <Link href={category.href} as={`/${category.link?.cached_url}`}>{category.name}</Link>
            }
          </li>
        ))}
      </ul>
    </StyledComponent>
      
  );

}

export default Component


const StyledComponent = styled.nav`

  display: flex;
  justify-content: center;
  height: 50px;
  border-bottom: solid 1px ${color.lines};
  box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
  background-color: ${color.pageBackground};

  ul, li, a{
    display: flex;
    height: 100%;
  }

  a{

    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    color: ${color.grey(20)};
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1.3;
    transition: all 0.15s ease-out;

    &:hover{
        color: ${color.black};
    }

  }


`