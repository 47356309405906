import React, {useMemo} from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'


// Get Rotation Style
const getIconRotation = (props) => {

  switch(props.iconDirection) {
    case 'up':
      return 'rotate(180deg)';
    case 'left':
      return 'rotate(90deg)';
    case 'right':
      return 'rotate(-90deg)';
    default:
      return 'none';
  };

}


// Component
type Props = {
  iconName: string,
  iconTitle?: string,
  iconDirection?: string,
  className?: string
}

const defaultProps = {
  iconTitle: 'Icon',
  iconDirection: 'down'
}

const Component: React.FC<Props> = (props) => {

  // Dynamically import the icon we're rendering and if it doesn't exist then render the default icon
  const IconComponent = useMemo(() => {
    return dynamic(() => import(`./data/${props.iconName}`).catch(err => {
      return import(`./data/chevron`);
    }));
  } , [props.iconName]);

  
  return (
    <StyledIcon {...props} className="icon" style={{transform: props.iconDirection ? getIconRotation(props) : ''}}>
      <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <title>{props.iconTitle}</title>
        <IconComponent />
      </svg>
    </StyledIcon>
  )

}

Component.defaultProps = defaultProps;

export default Component;

const StyledIcon = styled.div`

  display: flex;
  width: 24px;
  height: 24px;
  font-size: 0;

  svg{
    width: 100%;
  }

`
