import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import Icon from 'components/icons/icon';

import HeaderCloseBar from 'components/layout/header/headerCloseBar';

import theme, {bp, color} from 'settings/theme';


// Component
const Component = (props) => {

  const categories = props.masterTemplateData?.content?.info_categories || []

  return (

    <StyledComponent {...props} className="infoMenuLarge">
      <div className="infoMenuLarge-inner">
        <div className="infoMenuLarge-columns">

          {categories.map((category) => (

            <div className="infoMenuLarge-block" key={category._uid}>
              <div className="infoMenuLarge-title">
                {category.name}
              </div>
              <ul>
                {category.pages.map((page) => (
                  <InfoLink unsetItemHovered={props.unsetItemHovered} key={page._uid} href={`/[...slug]`} as={`/${page.link.cached_url}`}>{page.name}</InfoLink>
                ))}
              </ul>
            </div>

          ))}
        
        </div>
      </div>
      <HeaderCloseBar onClick={props.unsetItemHovered} />
    </StyledComponent>
      
  );

}

export default Component;


const StyledComponent = styled.nav`

  display: block;
  border-bottom: solid 1px ${color.lines};
  box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
  background-color: ${color.pageBackground};

  .infoMenuLarge-inner{
    display: flex;
    justify-content: center;
    padding: 40px;
  }

  .infoMenuLarge-columns{
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    width: 100%;
    max-width: 1260px;
    grid-template-columns: repeat( 4, minmax(0, 25%) );
  }

  .infoMenuLarge-block{

    .infoMenuLarge-title{
      margin-bottom: 14px;
      color: ${color.grey(20)};
      font-family: ${theme.typography.fontFamilySecondary};
      font-size: 18px;
      font-weight: bold;
    }

    ul{

      .infoLink{
        margin-bottom: 2px;
        font-family: ${theme.typography.fontFamilySecondary};
        font-size: 16px;
      }

    }

  }

`


const InfoLink = (props) => {

  const href = props.as.indexOf("/category") > -1 ? "/category/[...slug]" : props.href;
  
  return (
    <StyledInfoLink className="infoLink">
      <Link href={href} as={props.as} onClick={() => props.unsetItemHovered()}>
        <div className="infoLink-icon">
          <Icon iconName="chevron" iconDirection="right" />
        </div>
        <div className="infoLink-text">{props.children}</div>
      </Link>
    </StyledInfoLink>
  );

}

const StyledInfoLink = styled.div`

  a{
    display: flex;
  }

  .infoLink-icon{

    transition: transform 0.15s ease-out;

    .icon{

      width: 10px;

      svg{
          path{fill: ${color.grey(20)};}
      }

    }

  }

  .infoLink-text{
    margin-left: 10px;
    transition: color 0.15s ease-out;
  }

  a:hover{

    .infoLink-icon{

      transform: translateX(3px);

      .icon{

        svg{
          path{fill: ${color.black};}
        }

      }

    }

    .infoLink-text{
      color: ${color.black};
    }

  }

`