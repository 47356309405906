import React from 'react'
import styled from 'styled-components'

import Icon from 'components/icons/icon'

import theme, {bp, color} from 'settings/theme'


type Props = {
    toggleMenu: (menuName: string) => void
}


// Component
const Component: React.FC<Props> = (props) => {

    return (

        <StyledComponent className="footerCloseBar">
            <button onClick={() => props.toggleMenu('')}>
                <div>CLOSE</div>
                <Icon iconName="Chevron" />
            </button>
        </StyledComponent> 
        
    )

}

export default Component


const StyledComponent = styled.div`

    display: flex;
    justify-content: flex-end;
    height: 40px;
    padding-right: 16px;
    border-top: solid 1px ${color.lines};
    border-bottom: solid 1px ${color.lines};
    background-color: white;

    button{

        display: flex;
        align-items: center;
        height: 40px;
        color: ${color.grey(20)};
        font-size: 12px;
        font-weight: bold;

        .icon{

            width: 14px;
            height: 14px;
            margin-left: 6px;

            svg{fill: ${color.grey(20)}}

        }

    }

`
