import React, {useMemo} from 'react'
import styled, {css} from 'styled-components'

import Icon from 'components/icons/icon'

import theme, {bp, color} from 'settings/theme'


type Props = {
    menuOpen: string,
    toggleMenu: (menuName: string) => void
}

type StyledProps = {
    isOn: boolean
}

// Component
const Component: React.FC<Props> = (props) => {

    const isOn = useMemo(() => props.menuOpen !== '', [props.menuOpen])

    return (

        <StyledComponent isOn={isOn} onClick={() => props.toggleMenu('')} className="footerBackground" />

    )

}

export default Component


const StyledComponent = styled.div<StyledProps>`

    z-index: 1;
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s ease-out;

    ${props => props.isOn && css`
    
        pointer-events: all;
        opacity: 1;
    
    `}

`
