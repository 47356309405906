import { useState, useEffect } from 'react'

import theme from 'settings/theme'

type BreakpointState = {
  size: number,
  oldSize: number,
  min: number,
  max: number
};

const breakpoints = theme.breakpoints;

const defaultValue = {
  size: 0,
  oldSize: 0,
  min: theme.breakpoints[0],
  max: theme.breakpoints[1] -1
};


const calculateBreakpoint = (oldState: BreakpointState) => {

  let result = defaultValue;

  theme.breakpoints.forEach((min, index) => {

    const max = breakpoints.length === index + 1 ? 1000000 : breakpoints[index+1] - 1;

    if(min <= window.innerWidth && window.innerWidth <= max){
      result = {
        size: index + 1,
        oldSize: oldState.size,
        min: min,
        max: max
      };
    }

  })

  return result;

}

const useBreakpoints = () => {


  const [state, setState] = useState<BreakpointState>(defaultValue);

  const handleResize = () => {
    if(typeof window !== 'undefined'){
      var newValue = calculateBreakpoint(state);
      setState(newValue);
    }
    else{
      setState(defaultValue);
    }
  }
  
  // Run on mount
  useEffect(() => handleResize(), []);

  useEffect(() => {
    if(typeof window !== 'undefined'){
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });

  return state;

}

export type { BreakpointState };

export default useBreakpoints;