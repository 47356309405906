import React from 'react'
import styled, {css} from 'styled-components'

import FooterCloseBar from 'components/layout/footer/footerCloseBar'
import FooterMenuTitle from 'components/layout/footer/footerMenuTitle'
import FooterMenuItem from 'components/layout/footer/footerMenuItem'

import theme, {bp, color} from 'settings/theme'


type Props = {
    isOpen: boolean,
    toggleMenu: (menuName: string) => void,
    masterTemplateData: any
}

type StyledProps = {
    isOpen: boolean
}


// Component
const Component: React.FC<Props> = (props) => {

    const categories = props.masterTemplateData?.content?.info_categories || []

    return (

        <StyledComponent isOpen={props.isOpen} className="infoMenuSmall">
            
            <FooterCloseBar toggleMenu={props.toggleMenu} />

            <div className="infoMenuSmall-main">
                {categories.map((category, index) => (

                    <div className="infoMenuSmall-block" key={category._uid}>
                        <FooterMenuTitle>{category.name}</FooterMenuTitle>
                        {index == 0 &&
                            <>
                                <FooterMenuItem href="/" onClick={() => props.toggleMenu('')}>
                                    Home
                                </FooterMenuItem>
                                <FooterMenuItem href="/blog" onClick={() => props.toggleMenu('')}>
                                    Our Blog
                                </FooterMenuItem>
                            </> 
                        }
                        <ul>
                            {category.pages.map((page) => (
                                <FooterMenuItem href="/[...slug]" as={`/${page.link.cached_url}`} onClick={() => props.toggleMenu('')} key={page._uid}>
                                    {page.name}
                                </FooterMenuItem>
                            ))}
                        </ul>
                        {index == 0 &&
                            <>
                                <FooterMenuItem href="/contact" onClick={() => props.toggleMenu('')}>
                                    Contact Us
                                </FooterMenuItem>
                            </> 
                        }
                    </div>


                ))}
            </div>

        </StyledComponent> 
        
    )

}

export default Component


const StyledComponent = styled.div<StyledProps>`

    z-index: 2;
    position: fixed;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 180px);
    background-color: ${color.pageBackground};
    

    opacity: ${props => props.isOpen ? 1 : 0} ;
    transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(30%)'} ;
    transition: all 0.1s ease-out;

    pointer-events: none;

    ${props => props.isOpen && css`
        pointer-events: all;
    `}


    .infoMenuSmall-main{
        flex: 1 1 auto;
        padding: 24px 30px;
        overflow-y: auto;
    }

    .infoMenuSmall-block{
        
        &:not(:last-child){
            margin-bottom: 30px;
        }

    }


`
