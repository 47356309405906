import React, {useMemo} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';


type Props = {};


// Component
const Component: React.FC<Props> = (props) => {

  const year = useMemo(() => {
    const d = new Date();
    const year = d.getFullYear();
    return year;
  }, []);

  return (

    <StyledComponent className="largeFooter">

      <div className="largeFooter-social">
        <a href="https://x.com/wheelfreedom" target="_blank">
          <Image src="/images/social/x.svg" alt="X" width="40" height="40" />
        </a>
        <a href="https://www.instagram.com/wheelfreedom" target="_blank">
          <Image src="/images/social/instagram.svg" alt="Instagram" width="40" height="40" />
        </a>
        <a href="https://www.facebook.com/wheelfreedom" target="_blank">
          <Image src="/images/social/facebook.svg" alt="Facebook" width="40" height="40" />
        </a>
        <a href="https://www.youtube.com/channel/UCX5a73DANfiaSa57FJj5mRw" target="_blank">
          <Image src="/images/social/youtube.svg" alt="Youtube" width="40" height="40" />
        </a>
        <a href="https://www.linkedin.com/company/wheelfreedom" target="_blank">
          <Image src="/images/social/linkedin.svg" alt="LinkedIn" width="40" height="40" />
        </a>
      </div>

      <div className="largeFooter-text">
          
        <div className="largeFooter-copyright">
          All contents &copy; {year} Wheel Freedom Ltd
        </div>
        <div className="largeFooter-links">
          <Link href="/blog/[[...slug]]" as="/blog">
            Our Blog
          </Link>
          <Link href="/[...slug]" as="/privacy-policy">
            Privacy Policy
          </Link>
          <Link href="/[...slug]" as="/information-centre">
            Information Centre
          </Link>
        </div>
        <div className="largeFooter-cognio">
          Web development by <a href="https://www.cogniodigital.com"><span>Cognio Digital</span><span><img src="/images/cognio.svg" alt="Cognio Digital Logo" /></span></a>
        </div>

      </div>
        
    </StyledComponent> 
      
  )

}

export default Component


const StyledComponent = styled.div`

  display: none;
  flex-direction: column;
  width: 100%;
  
  border-top: solid 1px ${color.lines};
  background-color: white;

  .largeFooter-social{
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 60px;  
    padding-top: 30px;
  }

  .largeFooter-text{

    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100px;
    padding-bottom: 40px;
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 14px;

    a{
      text-decoration: underline;
    }

    > div:not(:last-child){
      margin-right: 20px;
    }

    .largeFooter-copyright{
      flex: 0 0 auto;
    }

    .largeFooter-links{

      flex: 0 0 auto;

      > *:not(:last-child){
        margin-right: 20px;
      }

    }

    .largeFooter-cognio{

      flex: 0 0 auto;
      white-space: nowrap;

      a{

        display: inline-flex;
        align-items: flex-end;

        span:last-child{
          margin-left: 6px;
        }

        img{
          width: 30px;
        }

      } 

    }

  }

  
  @media (min-width: ${bp(4)}) {
    display: flex;
  }  

`
