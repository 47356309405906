import React, {useState} from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import FooterBackground from 'components/layout/footer/footerBackground';
import FooterMain from 'components/layout/footer/footerMain';
import ProductMenuSmall from 'components/layout/footer/productMenuSmall';
import InfoMenuSmall from 'components/layout/footer/infoMenuSmall'
import SearchMenuSmall from 'components/layout/footer/searchMenuSmall';
import LargeFooter from 'components/layout/footer/largeFooter';

import theme, {bp, color} from 'settings/theme';

import useBreakpoints from 'hooks/useBreakpoints';


type Props = {
    masterTemplateData: any
};

// Component
const Component: React.FC<Props> = (props) => {

  const currentBreakpoint: any = useBreakpoints();
  const [menuOpen, setMenuOpen] = useState('');


  const toggleMenu = (menuName: string): void => {

    if(menuName === menuOpen){
        setMenuOpen('');
    }
    else{
        setMenuOpen(menuName);
    }

  };


  return (
    <>

      <StyledSocials>
        <a href="https://x.com/wheelfreedom" target="_blank">
          <Image src="/images/social/x.svg" alt="X" width="32" height="32" />
        </a>
        <a href="https://www.instagram.com/wheelfreedom" target="_blank">
          <Image src="/images/social/instagram.svg" alt="Instagram" width="32" height="32" />
        </a>
        <a href="https://www.facebook.com/wheelfreedom" target="_blank">
          <Image src="/images/social/facebook.svg" alt="Facebook" width="32" height="32" />
        </a>
        <a href="https://www.youtube.com/channel/UCX5a73DANfiaSa57FJj5mRw" target="_blank">
          <Image src="/images/social/youtube.svg" alt="Youtube" width="32" height="32" />
        </a>
        <a href="https://www.linkedin.com/company/wheelfreedom" target="_blank">
          <Image src="/images/social/linkedin.svg" alt="LinkedIn" width="32" height="32" />
        </a>
      </StyledSocials>

      <StyledSpacer />

      <StyledSmallFooter className="footer">

        <FooterBackground 
          menuOpen={menuOpen} 
          toggleMenu={toggleMenu} 
        />

        <FooterMain 
          menuOpen={menuOpen} 
          toggleMenu={toggleMenu} 
        />

        <ProductMenuSmall 
          masterTemplateData={props.masterTemplateData} 
          isOpen={menuOpen === 'products'} 
          toggleMenu={toggleMenu}  
        />

        <InfoMenuSmall 
          masterTemplateData={props.masterTemplateData} 
          isOpen={menuOpen === 'info'} 
          toggleMenu={toggleMenu}  
        />

        <SearchMenuSmall 
          isOpen={menuOpen === 'search'} 
          toggleMenu={toggleMenu}  
        />

      </StyledSmallFooter>

      <LargeFooter />
    
    </>
  );

}

export default Component;


const StyledSmallFooter = styled.footer`

  display: block;
  width: 100%;

  @media (min-width: ${bp(4)}) {
    display: none;
  }

`

const StyledSocials = styled.footer`

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 120px;
  padding-bottom: 40px;
  border-top: solid 1px ${color.lines};

  a{
    height: 32px;
  }

  @media (min-width: ${bp(4)}) {
    display: none;
  }

`


const StyledSpacer = styled.div`
    
  display: block;
  height: 60px;

  @media (min-width: ${bp(4)}) {

    display: none;

  }  

`;