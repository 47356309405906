import React from 'react'
import styled from 'styled-components'


import theme, {bp, color} from 'settings/theme'




const StyledComponent = styled.div`

    margin-bottom: 0.5rem;
    color: ${color.grey(10)};
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 16px;
    font-weight: bold;

    @media (min-width: ${bp(3)}) {

        font-size: 18px;

    }

`

export default StyledComponent
